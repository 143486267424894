import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductsService } from '../../services/products.service';

@Component({
  templateUrl: './products.html',
  styleUrls: ['./products.scss']
})

export class ProductsComponent implements OnInit {
  products: Array<{}> = [];
  categoryID: Number;
  category: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private service: ProductsService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.categoryID = +params["id"];
      this.getProductList();
    })
  }

  async getProductList() {
    this.category = await this.service.getCategory(this.categoryID);
    if (this.category.err) {
      this.snackBar.open(this.category.error, "Inchide", {
        duration: 2500,
      });
    }
  }

  goToProduct(productsID, productID) {
    this.router.navigate(['', { id: productsID, productID: productID }]);
  }
}
