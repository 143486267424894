import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EntitiesService {
    constructor(private http: HttpClient) { }

    async getCategory(categoryID) {
        let data = await this.http.get("/assets/entities.json").toPromise();
        if (data["categories"]) {
            return data["categories"].filter((category) => { return category._id === categoryID; })[0];
        } else {
            return { err: data };
        }
    }

    async getEntity(categoryID, entityID) {
        let data = await this.http.get("/assets/entities.json").toPromise();
        if (data["categories"]) {
            let category = data["categories"].filter((category) => { return category._id === categoryID; })[0];
            if (category) {
                let entity = category.entities.filter((entity) => { return entity._id === entityID; })[0];
                if (entity) {
                    return entity;
                }
            }
        } else {
            return { err: data };
        }
    }
}