<div class="gallery-wrapper">
    <div class="title mat-display-1" [ngsReveal]="{ reset: false, easing: 'ease'}">
        Special pentru copiii
    </div>
    <div class="grid cakes-babies-gallery" [ngsReveal]="{ reset: false, easing: 'ease'}">
        <div class="grid-item"
            *ngFor="let item of previewCakes.babies; let i = index">
            <img [src]="item.src" (click)="openGallery('cakesBabiesGallery', item.index)"/>
        </div>
    </div>
    <div class="button-gallery" [ngsReveal]="{ reset: false, easing: 'ease'}">
        <button mat-raised-button color="primary" (click)="openGallery('cakesBabiesGallery')">
            Vezi mai multe
        </button>
    </div>
    <div class="title mat-display-1" [ngsReveal]="{ reset: false, easing: 'ease'}">
        Aniversări
    </div>
    <div class="grid cakes-birth-gallery" [ngsReveal]="{ reset: false, easing: 'ease'}">
        <div class="grid-item"
            *ngFor="let item of previewCakes.birthdays; let j = index">
            <img [src]="item.src" (click)="openGallery('cakesBirthdaysGallery', item.index)" />
        </div>
    </div>
    <div class="button-gallery" [ngsReveal]="{ reset: false, easing: 'ease'}">
        <button mat-raised-button color="primary" (click)="openGallery('cakesBirthdaysGallery')">
            Vezi mai multe
        </button>
    </div>
    <div class="title mat-display-1" [ngsReveal]="{ reset: false, easing: 'ease'}">
        Tematice
    </div>
    <div class="grid cakes-special-gallery" [ngsReveal]="{ reset: false, easing: 'ease'}">
        <div class="grid-item"
            *ngFor="let item of previewCakes.custom; let k = index">
            <img [src]="item.src" (click)="openGallery('cakesSpecialOcasionsGallery', item.index)"/>
        </div>
    </div>
    <div class="button-gallery" [ngsReveal]="{ reset: false, easing: 'ease'}">
        <button mat-raised-button color="primary" (click)="openGallery('cakesSpecialOcasionsGallery')">
            Vezi mai multe
        </button>
    </div>
    <div class="title mat-display-1" [ngsReveal]="{ reset: false, easing: 'ease'}">
        Nunți
    </div>
    <div class="grid cakes-weddings-gallery" [ngsReveal]="{ reset: false, easing: 'ease'}">
        <div class="grid-item"
            *ngFor="let item of previewCakes.weddings; let l = index">
            <img [src]="item.src" (click)="openGallery('cakesWeddingsGallery', item.index)"/>
        </div>
    </div>
    <div class="button-gallery" [ngsReveal]="{ reset: false, easing: 'ease'}">
        <button mat-raised-button color="primary" (click)="openGallery('cakesWeddingsGallery')">
            Vezi mai multe
        </button>
    </div>
</div>
