<div class="section-1">
    <div class="section-header section-blur">
        <div [ngsReveal]="{ reset: false, easing: 'ease'}" class="section-container">
            <div class="section-header-title headerFont">Lorem ipsum</div>
            <div class="section-header-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ipsum
                nibh,
                facilisis suscipit fringilla a.</div>
            <div><button mat-raised-button
                    (click)="goToLink('https://topcaffe-comanda-tortul-tau.paperform.co/')">Comandă
                    tortul tău</button></div>
        </div>
    </div>
</div>

<entities id="section-3" [categoryID]="2"></entities>