<div [ngClass]="'section'" style="background-image: url('{{entity.image}}');">
  <div class="section-header section-blur">
    <div [ngsReveal]="{ reset: false, easing: 'ease'}" class="section-container">
      <div class="section-header-title headerFont">{{entity.name}}</div>
      <div class="section-header-description" *ngIf="entity.description">{{entity.description}}</div>
      <div class="section-header-description" *ngIf="entity.tags && entity.tags.length > 0">
        <mat-chip-list aria-label="{{entity.name}}">
          <mat-chip *ngFor="let tag of entity.tags" color="primary" selected>{{tag}}</mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>
</div>

<div class="entities" fxLayout="row" fxFlexFill fxLayoutAlign="stretch" fxLayout.xs="column nowrap">
  <mat-card [ngsReveal]="{ reset: false, easing: 'ease'}" class="entity" fxFlex="25%" fxFlex.sm="50%" fxFlex.xs="100%"
    *ngFor="let subentity of filtered(entity.subentities)">

    <img mat-card-image (mouseover)="toggleHover(subentity)" (mouseleave)="removeHover(subentity)" src="{{'/assets/entities/' + subentity.image + '.png'}}" alt="{{subentity.name}}">

    <mat-card-header>
      <mat-card-title class="headerFont">{{subentity.name}}</mat-card-title>
      <mat-card-subtitle>{{subentity.description}}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content *ngIf="subentity.variations && subentity.variations">
      <div> Sortiment
        <span *ngFor="let variation of subentity.variations" class="content-text">• {{variation}} </span>
      </div>
    </mat-card-content>
 
    <mat-card-content>
      <div>{{subentity.price}}
        <span *ngIf="subentity.weight" class="content-text">{{subentity.weight}}</span>
      </div>
    </mat-card-content>

    <mat-card-content *ngIf="subentity.tags && subentity.tags.length">
      <mat-chip-list aria-label="{{subentity.name}}">
        <mat-chip *ngFor="let tag of subentity.tags" color="primary" selected>{{tag}}</mat-chip>
      </mat-chip-list>
    </mat-card-content>

    <mat-card-content *ngIf="subentity.alergens && subentity.alergens.length">
      <div> Alergeni
        <span *ngFor="let alergen of subentity.alergens" class="content-text">• {{alergen}} </span>
      </div>
    </mat-card-content>

    <mat-card-content *ngIf="subentity.suggestions && subentity.suggestions.length">
      <div> Recomandari
        <span *ngFor="let suggestion of subentity.suggestions" class="content-text">• {{suggestion}} </span>
      </div>
    </mat-card-content>

  </mat-card>
</div>