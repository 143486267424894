import { Component } from '@angular/core';

@Component({
  templateUrl: './contact.html',
  styleUrls: ['./contact.scss']
})

export class ContactComponent {
  title = 'top-caffe';
  config: any = {
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    spaceBetween: 30
  };

  configSweets: any = {
    nextButton: '.swiper-next-sweets',
    prevButton: '.swiper-prev-sweets',
    spaceBetween: 30,
    slidesPerView: 3,
    loop: true,
    breakpoints: {
      480: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      780: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      960: {
        slidesPerView: 2,
        spaceBetween: 20
      }
    }
  };

  goToLink(url: string) {
    window.open(url, "_blank");
  }
}
