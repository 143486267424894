<div [ngsReveal]="{ reset: false, easing: 'ease'}" class="section-header">
  <div class="section-header-title headerFont" style="color: #846F47;">{{category.name}}</div>
  <div>{{category.description}}</div>
</div>

<div class="entities" fxLayout="row" fxFlexFill fxLayoutAlign="stretch" fxLayout.xs="column nowrap">
  <mat-card [ngsReveal]="{ reset: false, easing: 'ease'}" class="entity" fxFlex="50%" fxFlex.sm="50%" fxFlex.xs="100%"
    *ngFor="let entity of category.entities">

    <img *ngIf="entity.googlemaps" mat-card-image src="{{entity.image}}" alt="{{entity.name}}"
      (click)="goToLink(entity.googlemaps)">
    <img *ngIf="entity.subentities" mat-card-image src="{{entity.image}}" alt="{{entity.name}}"
      [routerLink]="['', entity._id]">
    <img *ngIf="entity.redirect" mat-card-image src="{{entity.image}}" alt="{{entity.name}}"
    (click)="goToLink(entity.redirect)">

    <mat-card-header>
      <mat-card-title class="headerFont">{{entity.name}}</mat-card-title>
      <mat-card-subtitle *ngIf="entity.address1">{{entity.address1}} <br> {{entity.address2}}</mat-card-subtitle>
      <mat-card-subtitle *ngIf="entity.phone">{{entity.phone}}</mat-card-subtitle>
      <mat-card-subtitle *ngIf="entity.description">{{entity.description}}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content *ngIf="entity.openingtimes1">
      <p class="content-text">{{entity.openingtimes1}} <br> {{entity.openingtimes2}} <br> {{entity.openingtimes3}}</p>
    </mat-card-content>

    <mat-card-content *ngIf="entity.tags && entity.tags.length > 0">
      <mat-chip-list aria-label="{{entity.name}}">
        <mat-chip *ngFor="let tag of entity.tags" color="primary" selected>{{tag}}</mat-chip>
      </mat-chip-list>
    </mat-card-content>

    <mat-card-actions align="start">
      <button *ngIf="entity.subentities" mat-flat-button [routerLink]="['', entity._id]">Vezi Meniul</button>
      <a *ngIf="entity.googlemaps" mat-flat-button href="{{entity.googlemaps}}" target="_blank">Google Maps</a>
      <a *ngIf="entity.redirect" mat-flat-button href="{{entity.redirect}}" target="_blank">{{entity.label}}</a>
    </mat-card-actions>
  </mat-card>
</div>