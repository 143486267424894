import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ParallaxScrollModule } from 'ng2-parallaxscroll';
import { AppComponent } from './app.component';
import { ContactComponent } from '../features/contact/contact.component';
import { GalleryComponent } from '../features/gallery/gallery.component';
import { HomeComponent } from '../features/home/home.component';
import { ProductsComponent } from '../features/products/products.component';
import { ProductComponent } from '../features/product/product.component';
import { NotFoundComponent } from '../features/notFound/notFound.component';
import { FooterComponent } from '../components/footer/footer.component';
import { ProductsService } from '../services/products.service';
import { RouterModule, Router } from '@angular/router';
import { SafePipe } from '../components/sanitizer.pipe';
import { Angulartics2Module } from 'angulartics2';
import { NgsRevealModule } from 'ngx-scrollreveal';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { CookieLawModule } from 'angular2-cookie-law';
import { NgxLineClampModule } from 'ngx-line-clamp';
import { EntitiesService } from 'src/services/entities.service';
import { EntitiesComponent } from 'src/features/entities/entities.component';
import { EntityComponent } from 'src/features/entity/entity.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    GalleryComponent,
    ProductsComponent,
    EntityComponent,
    EntitiesComponent,
    ProductComponent,
    NotFoundComponent,
    FooterComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ParallaxScrollModule,
    Angulartics2Module.forRoot(),
    NgsRevealModule,
    GalleryModule.withConfig({
      imageSize: "contain",
      autoPlay: false,
      loadingStrategy: 'preload'
    }),
    LightboxModule,
    RouterModule.forRoot(
      [
        { path: "", component: EntityComponent },
        // { path: "contact", component: ContactComponent },
        // { path: ":id", pathMatch: 'full', component: EntityComponent },
        { path: '**', component: EntityComponent }
      ],
      {
        onSameUrlNavigation: 'reload',
        relativeLinkResolution: 'legacy'
      }
    ),
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FlexLayoutModule,
    NgxUsefulSwiperModule,
    CookieLawModule,
    NgxLineClampModule
  ],
  providers: [
    ProductsService,
    EntitiesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
