import { Component , AfterViewInit} from '@angular/core';

@Component({
  selector: 'footer',
  templateUrl: './footer.html',
  styleUrls: ['./footer.scss']
})
export class FooterComponent implements AfterViewInit{
  footerElement: any = null;
  content: any = null;
  year = new Date().getFullYear();

  footerID : any = 'copyright';
  mainContentWrapper : any = 'publicContent';

  constructor() {}

  calculatePadding(){
    setTimeout(() => {
        this.footerElement = document.getElementById(this.footerID);
      this.content = document.getElementById(this.mainContentWrapper);
      let heightOfContent = this.content.offsetTop + this.content.offsetHeight;
      let footerHeight = this.footerElement.offsetHeight;
      let windowHeight = window.innerHeight;
      if(heightOfContent + footerHeight > windowHeight)
        this.footerElement.style.marginTop = '50px';
      else
        this.footerElement.style.marginTop = (windowHeight - heightOfContent - footerHeight + 20) + 'px';
    }, 300);
  };

  ngAfterViewInit(){
    window.addEventListener('resize', this.calculatePadding.bind(this), false);
    document.addEventListener('DOMContentLoaded', ()=>{
      this.calculatePadding.call(this);
    }, false);
  }



}