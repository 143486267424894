<!--The content below is only a placeholder and can be replaced.-->
<div fxLayout="row wrap" fxLayoutAlign="center center" class="layout-wrapper">
    <div fxFlex="100%" fxFlex.lt-md="100%" class="flex-wrapper">
        <mat-sidenav-container>
            <mat-sidenav #sidenav role="navigation">
                <mat-nav-list>                    
                    <a mat-list-item [routerLink]="['']" (click)="sidenav.toggle()">
                        Acasă
                    </a>
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="panelOpened" (opened)="panelOpened = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Produse artizanale
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <button mat-menu-item (click)="panelOpened = false; sidenav.toggle()"
                                [routerLink]="['/101']">Torturi</button>
                            <button mat-menu-item (click)="panelOpened = false; sidenav.toggle()"
                                [routerLink]="['/102']">Prăjituri - Monoporții</button>
                            <button mat-menu-item (click)="panelOpened = false; sidenav.toggle()"
                                [routerLink]="['/103']">Praline - Ciocolată</button>
                            <button mat-menu-item (click)="panelOpened = false; sidenav.toggle()"
                                [routerLink]="['/104']">Biscuiți - Macaroons</button>
                            <button mat-menu-item (click)="panelOpened = false; sidenav.toggle()"
                                [routerLink]="['/105']">Chec - Cozonac</button>
                            <button mat-menu-item (click)="panelOpened = false; sidenav.toggle()"
                                [routerLink]="['/106']">Înghețată</button>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <a mat-list-item (click)="goToLink('https://topcaffe-aplica-acum.paperform.co/')">
                        Carieră - Aplică acum
                    </a>

                    <a mat-list-item (click)="sidenav.toggle();" [routerLink]="['/contact']">
                        Filiale - Contact
                    </a>

                    <a mat-list-item (click)="goToLink('https://topcaffe-comanda-tortul-tau.paperform.co/')">
                        Comandă tortul tău
                    </a>
                </mat-nav-list>
            </mat-sidenav>
            <mat-sidenav-content id="publicContent">
                <mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>
                <mat-toolbar class="nav-web" style="position: absolute;">
                    <div style="margin-top: 10px;">
                        <a class="logo" [routerLink]="['']">
                            <img width="90px" src="assets/logo.png" />
                        </a>
                    </div>
                    <span class="spacer"></span>
                    <div fxShow="true" fxHide.lt-md>
                        <!-- <button mat-button [routerLink]="['']">Acasă</button>
                        <button mat-button [matMenuTriggerFor]="menu">Produse artizanale</button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item [routerLink]="['/101']">Torturi</button>
                            <button mat-menu-item [routerLink]="['/102']">Prăjituri - Monoporții</button>
                            <button mat-menu-item [routerLink]="['/103']">Praline - Ciocolată</button>
                            <button mat-menu-item [routerLink]="['/104']">Biscuiți - Macaroons</button>
                            <button mat-menu-item [routerLink]="['/105']">Chec - Cozonac</button>
                            <button mat-menu-item [routerLink]="['/106']">Înghețată</button>
                        </mat-menu>

                        <button mat-button (click)="navigateSection('section-2')">Carieră</button>

                        <button mat-button (click)="navigateSection('section-3')">Filiale</button>

                        <button mat-raised-button (click)="goToLink('https://topcaffe-comanda-tortul-tau.paperform.co/')">Comandă tortul tău</button> -->

                    </div>
                    <div fxShow="true" fxHide.gt-sm="true">
                        <!-- <button mat-button (click)="sidenav.toggle()">
                            <mat-icon class="material-icons">menu</mat-icon>
                        </button> -->
                    </div>
                </mat-toolbar>
                <main>
                    <router-outlet></router-outlet>
                </main>
                <cookie-law position="bottom" expiration="1">
                    Acest site folosește cookie-uri. Continuarea navigării reprezintă acceptul dvs. pentru această
                    folosință. Pentru mai multe detalii privind gestionarea preferințelor privind cookie-uri,
                    accesați <a href="/assets/politica-de-cookies.html" target="_blank">aici</a>.
                </cookie-law>
                <footer></footer>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>