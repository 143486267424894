<div class="footer" id="copyright" fxLayout="row" fxLayout.lt-sm="column">
    <div class="contact" fxFlex="100" fxFlex.lt-sm="100" fxLayoutAlign="center start" fxLayout="column">
        <p><span style="font-weight: bold;">TEUROQ SRL</span><span style="padding-left: 5px;">RO13976665,
                J17/376/2001</span>
        </p>
        <p>
            <span class="material-icons md-light">location_on</span>
            Str. Republicii 5, 805300 Tecuci
        </p>
        <p>
            <span class="material-icons md-light">phone</span>
            0372 291 014, 0756 078 778
        </p>
        <p>
            <span class="mdi mdi-facebook"></span>
            <a href="https://www.facebook.com/topcaffe.tecuci/" target="_blank">/topcaffe.tecuci</a>
        </p>

        <p>
            <span class="mdi mdi-instagram"></span>
            <a href="https://www.instagram.com/topcaffe.tecuci/" target="_blank">@topcaffe.tecuci</a>
        </p>

        <p>
            <span class="material-icons md-light">email</span>
            administrator@teuroq.ro
        </p>
    </div>

    <div class="contact" fxFlex="100" fxFlex.lt-sm="100" fxLayoutAlign="center end" fxLayoutAlign.lt-sm="center start"
        fxLayout="column">
        <p>
            <span class="mdi mdi-file-document"></span>
            <a href="/assets/politica-de-confidentialitate.html" target="_blank">Politica de
                confidențialitate</a>
        </p>
        <p>
            <span class="mdi mdi-file-document"></span>
            <a href="/assets/politica-de-cookies.html" target="_blank">Politica de
                cookies</a>
        </p>
        <p>
            <span class="mdi mdi-copyright"></span>
            {{year}} TOPCAFFE
        </p>
    </div>
</div>