import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EntitiesService } from 'src/services/entities.service';

@Component({
  selector: 'entities',
  templateUrl: './entities.html',
  styleUrls: ['./entities.scss']
})

export class EntitiesComponent implements OnInit {
  @Input() categoryID: Number;
  products: Array<{}> = [];
  category: any = {};

  constructor(private router: Router,
    private snackBar: MatSnackBar,
    private service: EntitiesService) { }

  ngOnInit() {
    this.getEntitiesList();
  }

  async getEntitiesList() {
    this.category = await this.service.getCategory(this.categoryID);
    if (this.category.err) {
      this.snackBar.open(this.category.error, "Inchide", {
        duration: 2500,
      });
    }
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }
}
