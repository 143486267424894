import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductsService } from '../../services/products.service';
@Component({
  templateUrl: './product.html',
  styleUrls: ['./product.scss']
})

export class ProductComponent implements OnInit {
  product: any = {
    err: null
  };
  sameProducts: any = null;
  similarProducts: any = null;
  productID: Number;

  configSameProducts: any = {
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    spaceBetween: 30,
    slidesPerView: 3,
    loop: true,
    breakpoints: {
        480: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        780: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        960: {
          slidesPerView: 2,
          spaceBetween: 20
        }
    }
};

  configSimilarProducts: any = {
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    spaceBetween: 30,
    slidesPerView: 3,
    loop: true,
    breakpoints: {
        480: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        780: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        960: {
          slidesPerView: 2,
          spaceBetween: 20
        }
    }
};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private service: ProductsService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.productID = +params["id"];
      this.getProduct();
    })
  }

  async getProduct(){
    let data = await this.service.getProduct(this.productID);
    this.product = data.foundProduct;
    this.sameProducts = data.sameProducts;
    this.similarProducts = data.similarProducts;
    if (this.product.err){
      this.snackBar.open(this.product.err, "Inchide", {
        duration: 2500
      });
    }
  }
}
