<div class="page-not-found">
    <div class="dog-image headerFont">
        <img src="assets/404_2.jpg" width="500">
    </div>
    <div class="right-content">
        <h2 class="error-code">
            404
        </h2>
        <h3 class="error-message">
            Oops, ce-ai făcut, Bobiță?
        </h3>
        <p class="error-submessage">Pagina pe care o cauți nu există. Întoarce-te <a href="/">acasă</a> și mai încearcă.</p>
    </div>
</div>