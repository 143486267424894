<div class="products-wrapper">
  <div [ngClass]="'section-' + categoryID">
    <div class="header-text headerFont" [ngsReveal]="{ reset: false, easing: 'ease-in'}">
      {{category.name}}
    </div>
  </div>
  <div class="products" fxLayout="row" fxFlexFill fxLayoutAlign="center stretch" fxLayout.xs="column nowrap">
    <mat-card class="product" fxFlex="25%" fxFlex.sm="40%" fxFlex.xs="100" *ngFor="let product of category.products">
      <mat-card-header>
        <mat-card-title>{{product.name}}</mat-card-title>
      </mat-card-header>
      <img mat-card-image src="{{product.image}}" alt="Photo of a Shiba Inu" [routerLink]="['/produs', product._id]">
      <mat-card-content>
        <p ngxLineClamp [lineCount]="2" [text]="product.description" class="short-description">
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button [routerLink]="['/produs', product._id]">DETALII</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
