import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EntitiesService } from 'src/services/entities.service';

@Component({
  templateUrl: './entity.html',
  styleUrls: ['./entity.scss']
})

export class EntityComponent implements OnInit {
  categoryID: number = 1; // produse artizanale
  entity: any = {};
  entityID: number = 101; // torturi

  constructor(private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private service: EntitiesService) { }

  ngOnInit() {
    this.getEntity();

    // this.route.params.subscribe(params => {
    //   this.entityID = +params["id"];
    //   this.getEntity();
    // })
  }

  filtered(subentities: any) {
    if (!subentities) return;
    return subentities.filter(e => e.visible);
  }

  toggleHover(subentity: any) {
    return;
    subentity.image = subentity.image + '-full';
  }

  removeHover(subentity: any) {
    return;
    subentity.image = subentity.image.replace('-full', '');
  }

  async getEntity() {
    this.entity = await this.service.getEntity(this.categoryID, this.entityID);

    if (!this.entity) {
      this.router.navigate(['/']);
    }

    if (this.entity.err) {
      this.snackBar.open(this.entity.error, "Inchide", {
        duration: 2500,
      });
    }
  }
}
