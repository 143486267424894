<div class="section-1">
    <div class="section-header section-blur">
        <div [ngsReveal]="{ reset: false, easing: 'ease'}" class="section-container">
            <div class="section-header-title headerFont">Lorem ipsum</div>
            <div class="section-header-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ipsum
                nibh,
                facilisis suscipit fringilla a, vehicula vel velit. </div>
            <div>
                <button mat-raised-button>Descoperă</button>
            </div>
        </div>
    </div>
</div>

<entities [categoryID]="3"></entities>

<div id="section-4" class="section-4">
    <div class="section-header section-blur">
        <div [ngsReveal]="{ reset: false, easing: 'ease'}" class="section-container">
            <div class="section-header-title headerFont">Povestea noastră</div>
            <div class="section-header-description">Esti curios de un nou job? Cu normă întreagă sau cu jumătate de normă?
                Multe
                lucruri sunt posibile
                cu noi. Vrem să ne dezvoltăm. Și, invers, dorim să ne ajutăm și angajații să se dezvolte.</div>
            <div><button mat-raised-button>Descoperă</button></div>
        </div>
    </div>
</div>

<entities [categoryID]="1"></entities>

<div id="section-2" class="section-2">
    <div class="section-header section-blur">
        <div [ngsReveal]="{ reset: false, easing: 'ease'}" class="section-container">
            <div class="section-header-title headerFont">Alătură-te echipei noastre</div>
            <div class="section-header-description">Esti curios de un nou job? Cu normă întreagă sau cu jumătate de normă?
                Multe
                lucruri sunt posibile
                cu noi. Vrem să ne dezvoltăm. Și, invers, dorim să ne ajutăm și angajații să se dezvolte.</div>
            <div><button mat-raised-button (click)="goToLink('https://topcaffe-aplica-acum.paperform.co/')">Aplică
                    acum</button></div>
        </div>
    </div>
</div>

<entities id="section-3" [categoryID]="2"></entities>

<div class="section-3">
    <div [ngsReveal]="{ reset: false, easing: 'ease'}" class="section-header section-blur">
        <div [ngsReveal]="{ reset: false, easing: 'ease'}" class="section-container">
            <div class="section-header-title headerFont">Partenerii noștri</div>
            <div class="section-header-description">Toate produsele noastre sunt pregătite cu atenție, din ingrediente
                naturale,
                de
                cea mai bună calitate, de la furnizori cu tradiție.</div>
        </div>
    </div>
</div>

