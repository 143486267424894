<div class="product-wrapper" fxLayout="column">
    <div class="header-text headerFont product-name" fxFlex="100" [ngsReveal]="{ reset: false, easing: 'ease-in'}">
        {{product.name}}
    </div>
    <div class="product" [ngsReveal]="{ reset: false, easing: 'ease-in'}" fxLayout.lt-md="column">
        <div class="product-image" fxFlex.lt-md="100" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center center">
            <img [src]="product.carousel_image"/>
        </div>
        <div class="product-details" fxLayout="column" fxFlex="60" fxFlex.lt-md="100" fxLayoutAlign="center center">
            <p [innerHTML]="product.description"></p>
            <p class="price"><b>Preț:</b> {{product.price}}</p>
            <p class="weight"><b>Gramaj:</b> {{product.weight}}</p>
        </div>
    </div>
    <div class="header-text headerFont also-like" *ngIf="similarProducts" fxFlex="100" [ngsReveal]="{ reset: false, easing: 'ease-in'}">
        S-ar putea să-ți mai placă:
    </div>
    <div class="random-suggestions" *ngIf="similarProducts" [ngsReveal]="{ reset: false, easing: 'ease-in'}">
        <swiper [config]="configSimilarProducts" #swipperSimilar>
            <div class="swiper-wrapper">
                <div class="swiper-slide" fxLayout="column" fxLayoutAlign="center center" *ngFor="let item of similarProducts">
                    <div class="img-same-slide">
                        <img [src]="item.carousel_image" [routerLink]="['/produs/' + item._id]">
                    </div>
                    <div class="button-slide">
                        <button mat-raised-button color="primary" [routerLink]="['/produs/' + item._id]">{{item.name}}</button>
                    </div>
                </div>
            </div>
            <div class="swiper-button-next swiper-button-black" (click)="swipperSimilar.swiper.slideNext()">
            </div>
            <div class="swiper-button-prev swiper-button-black" (click)="swipperSimilar.swiper.slidePrev()">
            </div>
        </swiper>
    </div>
    <div class="header-text headerFont also-like" fxFlex="100" [ngsReveal]="{ reset: false, easing: 'ease-in'}">
        Din aceeași categorie:
    </div>
    <div class="same-suggestions" [ngsReveal]="{ reset: false, easing: 'ease-in'}">
        <swiper [config]="configSameProducts" #swipperSame>
            <div class="swiper-wrapper">
                <div class="swiper-slide" fxLayout="column" fxLayoutAlign="center center" *ngFor="let item of sameProducts">
                    <div class="img-same-slide">
                        <img [src]="item.carousel_image" [routerLink]="['/produs/' + item._id]">
                    </div>
                    <div class="button-slide">
                        <button mat-raised-button color="primary" [routerLink]="['/produs/' + item._id]">{{item.name}}</button>
                    </div>
                </div>
            </div>
            <div class="swiper-button-next swiper-button-black" (click)="swipperSame.swiper.slideNext()">
            </div>
            <div class="swiper-button-prev swiper-button-black" (click)="swipperSame.swiper.slidePrev()">
            </div>
        </swiper>
    </div>
</div>
