import { Component, OnInit } from '@angular/core';
import { Gallery, GalleryRef, GalleryItem, ImageItem } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';

@Component({
  templateUrl: './gallery.html',
  styleUrls: ['./gallery.scss']
})

export class GalleryComponent implements OnInit {

  cakesBabiesGallery = "cakesBabiesGallery";
  cakesBirthdaysGallery = "cakesBirthdaysGallery";
  cakesSpecialOcasionsGallery = "cakesSpecialOcasionsGallery";
  cakesWeddingsGallery = "cakesWeddingsGallery";

  cakes_babies_images: GalleryItem[];
  cakes_birthdays_images: GalleryItem[];
  cakes_specialOcasions_images: GalleryItem[];
  cakes_weddings_images: GalleryItem[];
  previewCakes: any = {};


  constructor(private gallery: Gallery, private lightbox: Lightbox){
  }

  ngOnInit() {

    this.previewCakes = {
        babies: [
          {
            src: "assets/cakes/preview/cake1.png",
            index: 1
          },
          {
            src: "assets/cakes/preview/cake2.png",
            index: 8
          },
          {
            src: "assets/cakes/preview/cake3.png",
            indez: 0
          }
        ],
        birthdays: [
          {
            src: "assets/cakes/preview/cake4.png",
            index: 1
          },
          {
            src: "assets/cakes/preview/cake5.png",
            index: 2
          },
          {
            src: "assets/cakes/preview/cake6.png",
            index: 14
          }
        ],
        custom: [
          {
            src: "assets/cakes/preview/cake7.png",
            index: 0
          },
          {
            src: "assets/cakes/preview/cake8.png",
            index: 7
          },
          {
            src: "assets/cakes/preview/cake9.png",
            index: 1
          }
        ],
        weddings: [
          {
            src: "assets/cakes/preview/cake10.png",
            index: 0
          },
          {
            src: "assets/cakes/preview/cake11.png",
            index: 6
          },
          {
            src: "assets/cakes/preview/cake12.png",
            index: 1
          }
        ]
    }

    this.cakes_babies_images = [
      new ImageItem({ src: 'assets/cakes/babies/tort_botez1.jpg', thumbnail: 'assets/cakes/babies/tort_botez1.jpg', thumb: "assets/cakes/babies/tort_botez1.jpg" }),
      new ImageItem({ src: 'assets/cakes/babies/tort_botez2.jpg', thumbnail: 'assets/cakes/babies/tort_botez2.jpg', thumb: "assets/cakes/babies/tort_botez2.jpg" }),
      new ImageItem({ src: 'assets/cakes/babies/tort_botez3.jpg', thumbnail: 'assets/cakes/babies/tort_botez3.jpg', thumb: "assets/cakes/babies/tort_botez3.jpg" }),
      new ImageItem({ src: 'assets/cakes/babies/tort_botez4.jpg', thumbnail: 'assets/cakes/babies/tort_botez4.jpg', thumb: "assets/cakes/babies/tort_botez4.jpg" }),
      new ImageItem({ src: 'assets/cakes/babies/tort_botez5.jpg', thumbnail: 'assets/cakes/babies/tort_botez5.jpg', thumb: "assets/cakes/babies/tort_botez5.jpg" }),
      new ImageItem({ src: 'assets/cakes/babies/tort_botez6.jpg', thumbnail: 'assets/cakes/babies/tort_botez6.jpg', thumb: "assets/cakes/babies/tort_botez6.jpg" }),
      new ImageItem({ src: 'assets/cakes/babies/tort_botez7.jpg', thumbnail: 'assets/cakes/babies/tort_botez7.jpg', thumb: "assets/cakes/babies/tort_botez7.jpg" }),
      new ImageItem({ src: 'assets/cakes/babies/tort_botez8.jpg', thumbnail: 'assets/cakes/babies/tort_botez8.jpg', thumb: "assets/cakes/babies/tort_botez8.jpg" }),
      new ImageItem({ src: 'assets/cakes/babies/tort_botez9.jpg', thumbnail: 'assets/cakes/babies/tort_botez9.jpg', thumb: "assets/cakes/babies/tort_botez9.jpg" })
    ];

    const cakeBabGalleryRef = this.gallery.ref(this.cakesBabiesGallery);
    cakeBabGalleryRef.load(this.cakes_babies_images);

    this.cakes_birthdays_images = [
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar1.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar1.jpg', thumb: "assets/cakes/birthdays/tort_aniversar1.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar2.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar2.jpg', thumb: "assets/cakes/birthdays/tort_aniversar2.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar3.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar3.jpg', thumb: "assets/cakes/birthdays/tort_aniversar3.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar4.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar4.jpg', thumb: "assets/cakes/birthdays/tort_aniversar4.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar5.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar5.jpg', thumb: "assets/cakes/birthdays/tort_aniversar5.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar6.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar6.jpg', thumb: "assets/cakes/birthdays/tort_aniversar6.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar7.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar7.jpg', thumb: "assets/cakes/birthdays/tort_aniversar7.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar8.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar8.jpg', thumb: "assets/cakes/birthdays/tort_aniversar8.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar9.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar9.jpg', thumb: "assets/cakes/birthdays/tort_aniversar9.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar10.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar10.jpg', thumb: "assets/cakes/birthdays/tort_aniversar10.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar11.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar11.jpg', thumb: "assets/cakes/birthdays/tort_aniversar11.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar12.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar12.jpg', thumb: "assets/cakes/birthdays/tort_aniversar12.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar13.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar13.jpg', thumb: "assets/cakes/birthdays/tort_aniversar13.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar14.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar14.jpg', thumb: "assets/cakes/birthdays/tort_aniversar14.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar15.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar15.jpg', thumb: "assets/cakes/birthdays/tort_aniversar15.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar16.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar16.jpg', thumb: "assets/cakes/birthdays/tort_aniversar16.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar17.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar17.jpg', thumb: "assets/cakes/birthdays/tort_aniversar17.jpg" }),
      new ImageItem({ src: 'assets/cakes/birthdays/tort_aniversar18.jpg', thumbnail: 'assets/cakes/birthdays/tort_aniversar18.jpg', thumb: "assets/cakes/birthdays/tort_aniversar18.jpg" })
    ];

    const cakeBirthGalleryRef = this.gallery.ref(this.cakesBirthdaysGallery);
    cakeBirthGalleryRef.load(this.cakes_birthdays_images);


    this.cakes_specialOcasions_images = [
      new ImageItem({ src: 'assets/cakes/special_ocasions/tort_special1.jpg', thumbnail: 'assets/cakes/special_ocasions/tort_special1.jpg', thumb: "assets/cakes/special_ocasions/tort_special1.jpg" }),
      new ImageItem({ src: 'assets/cakes/special_ocasions/tort_special2.jpg', thumbnail: 'assets/cakes/special_ocasions/tort_special2.jpg', thumb: "assets/cakes/special_ocasions/tort_special2.jpg" }),
      new ImageItem({ src: 'assets/cakes/special_ocasions/tort_special3.jpg', thumbnail: 'assets/cakes/special_ocasions/tort_special3.jpg', thumb: "assets/cakes/special_ocasions/tort_special3.jpg" }),
      new ImageItem({ src: 'assets/cakes/special_ocasions/tort_special4.jpg', thumbnail: 'assets/cakes/special_ocasions/tort_special4.jpg', thumb: "assets/cakes/special_ocasions/tort_special4.jpg" }),
      new ImageItem({ src: 'assets/cakes/special_ocasions/tort_special5.jpg', thumbnail: 'assets/cakes/special_ocasions/tort_special5.jpg', thumb: "assets/cakes/special_ocasions/tort_special5.jpg" }),
      new ImageItem({ src: 'assets/cakes/special_ocasions/tort_special6.jpg', thumbnail: 'assets/cakes/special_ocasions/tort_special6.jpg', thumb: "assets/cakes/special_ocasions/tort_special6.jpg" }),
      new ImageItem({ src: 'assets/cakes/special_ocasions/tort_special7.jpg', thumbnail: 'assets/cakes/special_ocasions/tort_special7.jpg', thumb: "assets/cakes/special_ocasions/tort_special7.jpg" }),
      new ImageItem({ src: 'assets/cakes/special_ocasions/tort_special8.jpg', thumbnail: 'assets/cakes/special_ocasions/tort_special8.jpg', thumb: "assets/cakes/special_ocasions/tort_special8.jpg" }),
      new ImageItem({ src: 'assets/cakes/special_ocasions/tort_special9.jpg', thumbnail: 'assets/cakes/special_ocasions/tort_special9.jpg', thumb: "assets/cakes/special_ocasions/tort_special9.jpg" })
    ];

    const cakeSpecGalleryRef = this.gallery.ref(this.cakesSpecialOcasionsGallery);
    cakeSpecGalleryRef.load(this.cakes_specialOcasions_images);


    this.cakes_weddings_images = [
      new ImageItem({ src: 'assets/cakes/weddings/tort_nunta1.jpg', thumbnail: 'assets/cakes/weddings/tort_nunta1.jpg', thumb: "assets/cakes/weddings/tort_nunta1.jpg" }),
      new ImageItem({ src: 'assets/cakes/weddings/tort_nunta2.jpg', thumbnail: 'assets/cakes/weddings/tort_nunta2.jpg', thumb: "assets/cakes/weddings/tort_nunta2.jpg" }),
      new ImageItem({ src: 'assets/cakes/weddings/tort_nunta3.jpg', thumbnail: 'assets/cakes/weddings/tort_nunta3.jpg', thumb: "assets/cakes/weddings/tort_nunta3.jpg" }),
      new ImageItem({ src: 'assets/cakes/weddings/tort_nunta4.jpg', thumbnail: 'assets/cakes/weddings/tort_nunta4.jpg', thumb: "assets/cakes/weddings/tort_nunta4.jpg" }),
      new ImageItem({ src: 'assets/cakes/weddings/tort_nunta5.jpg', thumbnail: 'assets/cakes/weddings/tort_nunta5.jpg', thumb: "assets/cakes/weddings/tort_nunta5.jpg" }),
      new ImageItem({ src: 'assets/cakes/weddings/tort_nunta6.jpg', thumbnail: 'assets/cakes/weddings/tort_nunta6.jpg', thumb: "assets/cakes/weddings/tort_nunta6.jpg" }),
      new ImageItem({ src: 'assets/cakes/weddings/tort_nunta7.jpg', thumbnail: 'assets/cakes/weddings/tort_nunta7.jpg', thumb: "assets/cakes/weddings/tort_nunta7.jpg" }),
      new ImageItem({ src: 'assets/cakes/weddings/tort_nunta8.jpg', thumbnail: 'assets/cakes/weddings/tort_nunta8.jpg', thumb: "assets/cakes/weddings/tort_nunta8.jpg" }),
      new ImageItem({ src: 'assets/cakes/weddings/tort_nunta9.jpg', thumbnail: 'assets/cakes/weddings/tort_nunta9.jpg', thumb: "assets/cakes/weddings/tort_nunta9.jpg" })
    ];

    const cakeWedGalleryRef = this.gallery.ref(this.cakesWeddingsGallery);
    cakeWedGalleryRef.load(this.cakes_weddings_images);
  }

  openGallery(galleryId: string, galleryIndex?: number) {
    if (galleryIndex)
      this.lightbox.open(galleryIndex, galleryId);
    else
      this.lightbox.open(0, galleryId);
  }

  ngOnDestroy() {
    this.gallery.ref(this.cakesBabiesGallery).destroy();
    this.gallery.ref(this.cakesBirthdaysGallery).destroy();
    this.gallery.ref(this.cakesSpecialOcasionsGallery).destroy();
    this.gallery.ref(this.cakesWeddingsGallery).destroy();
  }
}
