import { Component, HostListener, Output, EventEmitter } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';

import browserUpdate from 'browser-update';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'top-caffe';
  loading = false;
  panelOpened: any = false;

  @Output() public sidenavToggle = new EventEmitter();

  constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private router: Router) {
    browserUpdate({
      required: { e: -6, f: -6, o: -6, s: -3, c: -6 },
      insecure: true,
      style: "top",
      reminder: 0,
      shift_page_down: false,
      api: 2019.03
    })
    angulartics2GoogleAnalytics.startTracking();
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          window.scrollTo(0, 0);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  navigateSection(sectionID: string): any {
    const element = document.getElementById(sectionID) as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }
}
