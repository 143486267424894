import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ProductsService {
    constructor(
        private http: HttpClient
    ) {}

    async getCategory(categoryID){
        let data = await this.http.get("/assets/products/products.json").toPromise();
        if (data["categories"]){
            return data["categories"].filter((category) => {return category._id === categoryID;})[0];
        } else {
            return { err: data };
        }
    }

    async getProduct(productID){
        let data = await this.http.get("/assets/products/products.json").toPromise();
        if (data["categories"]){
            var foundProduct = null;
            var sameProducts = null;
            var similarProducts = null;
            data["categories"].forEach((category) => {
                category.products.forEach(product => {
                    if (product._id === productID){
                        foundProduct = product;
                        sameProducts = this.shuffle(category.products.filter(product => product._id != productID));
                    }
                })
            });
            if (foundProduct){
                similarProducts = this.getSimilarProducts(data["categories"], foundProduct);
            }
            return foundProduct ? {
                foundProduct,
                sameProducts,
                similarProducts
            } : {
                err: "Produsul nu a fost gasit"
            }
        } else {
            return { err: data };
        }
    }

    getSimilarProducts(categories, currentProduct){
        var similarProducts = [];
        categories.forEach((category) => {
            category.products.forEach((product) => {
                if (product["tags"].some(tag1 => currentProduct["tags"].indexOf(tag1) !== -1)
                && currentProduct._id != product._id){
                    similarProducts.push(product);
                }
            })
        })
        return similarProducts.length ? this.shuffle(similarProducts) : null;
    }
    
    //to be moved to a util service/class
    //but I am lazy
    shuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;

          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }

        return array;
    }
}
